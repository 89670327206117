
import { ConnectionType } from '@capacitor/network';
import { Build } from '@stencil/core';
import { CoreoApp } from '../../types';
import { Actions, TypeKeys } from '../actions';

export enum AppInstallStatus {
  READY = 1,
  FREE_TRIAL_EXPIRED,
  ACCESS_DENIED
}

export enum MediaDownloadStatus {
  IDLE = 1,
  DOWNLOADING,
  COMPLETE,
  ERROR,
  ABORTED
}

export interface MediaDownloadState {
  state: MediaDownloadStatus;
  progress?: number;
  error?: any
}

export interface AppSyncState {
  project: number;
  records: number;
}

const mediaDownloadStateMap = {
  [TypeKeys.APP_DOWNLOAD_MEDIA]: MediaDownloadStatus.DOWNLOADING,
  [TypeKeys.APP_DOWNLOAD_MEDIA_SUCCESS]: MediaDownloadStatus.COMPLETE,
  [TypeKeys.APP_DOWNLOAD_MEDIA_ERROR]: MediaDownloadStatus.ERROR,
  [TypeKeys.APP_DOWNLOAD_ATTACHMENTS]: MediaDownloadStatus.DOWNLOADING,
  [TypeKeys.APP_DOWNLOAD_ATTACHMENTS_SUCCESS]: MediaDownloadStatus.COMPLETE,
  [TypeKeys.APP_DOWNLOAD_ATTACHMENTS_ERROR]: MediaDownloadStatus.ERROR,
}

export type AppListSegment = 'installed' | 'all';

export interface AppStatus {
  id: number;
  status: AppInstallStatus;
  lastSync: number;
  welcomeLastSeen: {
    timestamp?: string,
    id?: number
  };
  mediaDownloadState: MediaDownloadState;
  attachmentDownloadState: MediaDownloadState;
  syncState: AppSyncState;
}

export interface AppSettings {
  savePhotoToGallery: boolean;
  syncAttachments: ConnectionType;
  formLocateOnStart: boolean;
}

export type AppEnvironment = 'prod' | 'dev' | 'local';
export interface AppState {
  ready: boolean;
  tutorialShown: boolean;
  offlineMigrationComplete: boolean;
  app: CoreoApp;
  apps: AppStatus[];
  appId: number;
  environment: AppEnvironment;
  settings: AppSettings;
  networkConnected: boolean;
  networkConnectionType: ConnectionType;
  syncId: number;
}

const initialState: AppState = {
  ready: false,
  tutorialShown: false,
  offlineMigrationComplete: false,
  app: null,
  appId: null,
  apps: [],
  environment: 'prod',
  settings: {
    savePhotoToGallery: false,
    syncAttachments: 'none',
    formLocateOnStart: true
  },
  networkConnected: true,
  networkConnectionType: null,
  syncId: null
};

const appReducer = (state: AppState = initialState, action: Actions): AppState => {
  switch (action.type) {
    case TypeKeys.APP_INIT: {
      return {
        ...state,
        ready: true
      };
    }

    case TypeKeys.APP_DISMISS_TUTORIAL: {
      return {
        ...state,
        tutorialShown: true
      };
    }

    case TypeKeys.APP_LOAD: {
      // If the current app has an update available flag, clear it
      const { appId, apps } = state;
      let newApps = apps;

      if (appId) {
        const idx = apps.findIndex(a => a.id === appId);
        if (idx !== -1) {
          newApps = [
            ...apps.slice(0, idx),
            {
              ...apps[idx],
              status: AppInstallStatus.READY,
              mediaDownloadState: {
                ...apps[idx].mediaDownloadState,
                state: MediaDownloadStatus.COMPLETE,
                progress: 0
              },
              attachmentDownloadState: {
                ...apps[idx].attachmentDownloadState,
                state: MediaDownloadStatus.COMPLETE,
                progress: 0
              }
            },
            ...apps.slice(idx + 1)
          ];
        }
      }

      return {
        ...state,
        apps: newApps
      }
    }

    case TypeKeys.APP_LOAD_SUCCESS: {
      const { app } = action;
      return {
        ...state,
        appId: app.id,
        app

      };
    }

    case TypeKeys.APP_INSTALL_SUCCESS: {
      const { app, timestamp } = action;

      const idx = state.apps.findIndex(a => a.id === app.id);

      const appStatus: AppStatus = {
        id: app.id,
        status: AppInstallStatus.READY,
        mediaDownloadState: { state: MediaDownloadStatus.IDLE },
        attachmentDownloadState: { state: MediaDownloadStatus.IDLE },
        welcomeLastSeen: {},
        lastSync: timestamp,
        syncState: {
          project: 0,
          records: 0
        }
      };

      if (idx === -1) {
        return {
          ...state,
          apps: [
            ...state.apps,
            appStatus
          ]
        }
      }

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          appStatus,
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_TRIAL_EXPIRED: {
      const { id } = action;
      const idx = state.apps.findIndex(a => a.id === id);
      return {
        ...state,
        appId: null,
        app: null,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            status: AppInstallStatus.FREE_TRIAL_EXPIRED
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_ACCESS_DENIED: {
      const { id } = action;
      const idx = state.apps.findIndex(a => a.id === id);
      return {
        ...state,
        appId: null,
        app: null,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            status: AppInstallStatus.ACCESS_DENIED
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_AVAILABLE: {
      const { id } = action;
      const idx = state.apps.findIndex(a => a.id === id);
      return {
        ...state,
        appId: null,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            status: AppInstallStatus.READY,
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_UNINSTALL: {
      const apps = state.apps.filter(app => app.id !== action.id);
      const unsetActive = state.appId === action.id;

      return {
        ...state,
        appId: unsetActive ? null : state.appId,
        app: unsetActive ? null : state.app,
        apps
      };
    }

    case TypeKeys.APP_SYNC: {
      const { id } = action;
      const idx = state.apps.findIndex(a => a.id === id);

      if (idx === -1) {
        return {
          ...state,
          syncId: id
        };
      }

      const app = state.apps[idx];
      const mediaDownloadState = { ...app.mediaDownloadState };
      const attachmentDownloadState = { ...app.attachmentDownloadState };

      if (mediaDownloadState.state !== MediaDownloadStatus.DOWNLOADING) {
        mediaDownloadState.state = MediaDownloadStatus.IDLE;
        mediaDownloadState.progress = 0;
        mediaDownloadState.error = null;
      }

      if (attachmentDownloadState.state !== MediaDownloadStatus.DOWNLOADING) {
        attachmentDownloadState.state = MediaDownloadStatus.IDLE;
        attachmentDownloadState.progress = 0;
        attachmentDownloadState.error = null;
      }

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...app,
            mediaDownloadState,
            attachmentDownloadState
          },
          ...state.apps.slice(idx + 1)
        ],
        syncId: id
      };


    }
    case TypeKeys.APP_SYNC_SUCCESS: {
      const { id, timestamp } = action;
      const idx = state.apps.findIndex(a => a.id === id);

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            lastSync: timestamp,
            syncState: {
              project: 0,
              records: 0
            }
          },
          ...state.apps.slice(idx + 1)
        ],
        syncId: null
      };
    }

    case TypeKeys.APP_WELCOME_SEEN: {
      const { pageId } = action;
      const idx = state.apps.findIndex(_app => _app.id === action.id);

      if (idx === -1) {
        return state;
      }

      const app = { ...state.apps[idx] };

      app.welcomeLastSeen = {
        timestamp: new Date().toISOString(),
        id: pageId
      }
      const apps = state.apps.map((item, index) => index !== idx ? item : app);

      return {
        ...state,
        apps
      };
    }

    case TypeKeys.APP_DOWNLOAD_MEDIA:
    case TypeKeys.APP_DOWNLOAD_MEDIA_ERROR:
    case TypeKeys.APP_DOWNLOAD_MEDIA_SUCCESS: {
      const idx = state.apps.findIndex(_app => _app.id === action.id);

      if (idx === -1) {
        return state;
      }

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            mediaDownloadState: {
              state: mediaDownloadStateMap[action.type],
              progress: action['progress'],
              error: action['error']
            }
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_DOWNLOAD_ATTACHMENTS:
    case TypeKeys.APP_DOWNLOAD_ATTACHMENTS_ERROR:
    case TypeKeys.APP_DOWNLOAD_ATTACHMENTS_SUCCESS: {
      const idx = state.apps.findIndex(_app => _app.id === action.id);

      if (idx === -1) {
        return state;
      }

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            attachmentDownloadState: {
              state: mediaDownloadStateMap[action.type],
              progress: action['progress'],
              error: action['error']
            }
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APP_SYNC_STATE_UPDATE: {
      const idx = state.apps.findIndex(_app => _app.id === action.id);
      if (idx === -1) {
        return state;
      }

      return {
        ...state,
        apps: [
          ...state.apps.slice(0, idx),
          {
            ...state.apps[idx],
            syncState: action.syncState
          },
          ...state.apps.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.APPS_RESET: {
      return {
        ...state,
        apps: [],
        app: null,
        appId: null
      };
    }

    case TypeKeys.APP_SWITCH_ENVIRONMENT: {

      if (action.env) {
        return {
          ...state,
          environment: action.env
        };
      }

      const environments: AppEnvironment[] = ['prod', 'dev'];
      if (Build.isDev) {
        environments.push('local');
      } else {
        environments.push('local');
      }
      const current = environments.indexOf(state.environment);

      return {
        ...state,
        environment: environments[(current + 1) % environments.length]
      };
    }

    case TypeKeys.APP_OFFLINE_MIGRATION_COMPLETE: {
      return {
        ...state,
        offlineMigrationComplete: true
      };
    }

    case TypeKeys.APP_UPDATE_SETTINGS: {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings
        }
      };
    }

    case TypeKeys.APP_UPDATE_NETWORK: {
      return {
        ...state,
        networkConnected: action.networkConnected,
        networkConnectionType: action.networkConnectionType
      };
    }

    default: {
      return state;
    }
  }
};

export default appReducer;
